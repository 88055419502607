import React, { ReactElement } from 'react'
import { Heading, Paragraph } from 'app/components/text'
import { SEO } from '../components/seo'

const NotFoundPage = (): ReactElement => (
  <>
    <SEO title='404: Not found' />
    <Heading>NOT FOUND</Heading>
    <Paragraph>Такой страницы не существует</Paragraph>
  </>
)

export default NotFoundPage
